import React from 'react';
import classNames from 'classnames';
import Text from '../text';

type Colors = 'red' | 'green' | 'pink' | 'gray' | 'yellow' | 'blue';
type Size = 'small' | 'normal';

const colorsMapper: {
  [key in Colors]: {
    background?: string;
    fontColor?: string;
    ghostFontColor: string;
    ghostBackground: string;
    ghostBorder: string;
    coloredText: string;
  };
} = {
  red: {
    background: 'bg-gradient-to-b from-red-500 to-pink-500',
    fontColor: 'text-white',
    ghostFontColor: 'text-gray-950 dark:text-white',
    ghostBackground: 'bg-transparent',
    ghostBorder: 'border border-solid border-red-to-pink',
    coloredText:
      'bg-gradient-to-b from-red-500 to-pink-500 text-transparent bg-clip-text',
  },
  green: {
    background: 'bg-green-500',
    fontColor: 'text-gray-950',
    ghostFontColor: 'text-gray-950 dark:text-white',
    ghostBackground: 'bg-transparent',
    ghostBorder: 'border border-solid border-green-500',
    coloredText: 'text-green-500',
  },
  pink: {
    background: 'bg-pink-500',
    fontColor: 'text-gray-950',
    ghostFontColor: 'text-gray-950 dark:text-white',
    ghostBackground: 'bg-transparent',
    ghostBorder: 'border border-solid border-pink-500',
    coloredText: 'text-pink-500',
  },
  gray: {
    background: 'bg-gray-600',
    fontColor: 'text-white',
    ghostFontColor: 'text-gray-500',
    ghostBackground: 'bg-transparent',
    ghostBorder: 'border border-solid border-gray-500',
    coloredText: 'text-gray-500',
  },
  yellow: {
    background: 'bg-yellow-600',
    fontColor: 'text-white',
    ghostFontColor: 'text-gray-950 dark:text-white',
    ghostBackground: 'bg-transparent',
    ghostBorder: 'border border-solid border-yellow-500',
    coloredText: 'text-yellow-500',
  },
  blue: {
    background: 'bg-blue-400',
    fontColor: 'text-white',
    ghostFontColor: 'text-gray-950 dark:text-white',
    ghostBackground: 'bg-transparent',
    ghostBorder: 'border border-solid border-yellow-500',
    coloredText: 'text-blue-400',
  },
};

type Props = {
  color: Colors;
  ghost?: boolean;
  size?: Size;
  pillClassName?: string;
  withTextColor?: boolean;
};

const Pill: React.FC<Props & React.HTMLAttributes<HTMLDivElement>> = ({
  color,
  ghost,
  size = 'normal',
  children,
  pillClassName = '',
  withTextColor = false,
  ...props
}) => {
  const {
    background,
    fontColor,
    ghostFontColor,
    ghostBackground,
    ghostBorder,
    coloredText,
  } = colorsMapper[color];

  const wrapperClassName = classNames(
    props.className,
    'inline-flex rounded-sm overflow-hidden',
  );
  const className = classNames(
    'inline-flex select-none',
    ghost ? ghostBackground : background,
    pillClassName,
    {
      [ghostBorder]: ghost,
      'px-2 py-1': size === 'normal',
      'py-0.5 px-1': size === 'small',
    },
  );
  const noGhostProps = {
    color: fontColor,
  };
  const ghostProps = {
    color: withTextColor ? coloredText : ghostFontColor,
  };

  return (
    <div className={wrapperClassName}>
      <div {...props} className={className}>
        <Text
          type="label-caps"
          size="regular"
          className="uppercase"
          {...(ghost ? ghostProps : noGhostProps)}
        >
          {children}
        </Text>
      </div>
    </div>
  );
};

export default Pill;
