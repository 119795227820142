import { ConnectorTypes } from '@customer-web-app/domains/settings-services/enums/connector-types';
import { ConnectorVendorCodes } from '@customer-web-app/domains/shared/enums/connector-vendor-codes';
import { AlertProduct } from '@customer-web-app/domains/alerts/enums/alert-product';
import {
  Artifact,
  SplitArtifacts,
} from '@customer-web-app/domains/alerts/models/artifact';
import { VerdictTypes } from '@customer-web-app/domains/alerts/enums/verdict-types';

export enum AlertSourceTypes {
  Email = 'email',
}

export enum AlertSourceMethods {
  EmailPhishingNotification = 'email_phishing_notification',
  EmailForward = 'email_forward',
}

export enum AlertTypes {
  Phishing = 'phishing',
  Endpoint = 'endpoint',
  Identity = 'identity',
  Network = 'network',
  Cloud = 'cloud',
  Generic = 'generic',
}

export enum AlertStatuses {
  Falsified = 'falsified',
  Incident = 'incident',
  Enriched = 'enriched',
  Pending = 'pending',
  RecommendedMalicious = 'recommended_malicious',
  RecommendedBenign = 'recommended_benign',
  LikelyBenign = 'likely_benign',
  Triaging = 'triaging',
}

export enum FalsificationResults {
  NoIncident = 'noIncident',
  IncidentFound = 'incidentFound',
}

export interface AlertIdentity {
  id?: string;
  displayName?: string;
  email: string;
}

interface AlertHash {
  md5: string;
  sha1: string;
  sha256: string;
}

interface AlertActor {
  user: string;
  br_userID: string;
  userDisplayName: string;
  type: string;
  labels: string[];
  outcome: string;
  fileName: string;
  filePath: string;
  commandLine: string;
  sessionProcessID: string;
  parentSessionProcessId: string;
  hash: AlertHash;
}

interface AlertMitreTechnique {
  technique: string;
  tactic: string;
  id: string;
}

export type AlertSeverity = 'critical' | 'high' | 'medium' | 'low' | 'info';

export type AlertPriority = 'high' | 'medium' | 'low';

export type AlertAction = 'Upload' | 'Download';

export type AlertDetectionOutcome =
  | 'detected'
  | 'prevented'
  | 'stopped'
  | 'quarantined'
  | 'blocked'
  | 'active';

export enum AlertAIEligible {
  Eligible = 'eligible',
}

export interface Alert {
  id: string;
  tenantID: string;
  sourceId?: string;
  sourceType?: AlertSourceTypes;
  sourceMethod?: AlertSourceMethods;
  alertDescription?: string;
  sourceDetail: {
    sourceMethod: string;
    sourceServerCode: string;
    tenantDomain: string;
    timestamp: Date;
    timestampAsIs: string;
    subject: string;
    threadTopic: string;
    urls: string[];
    urlInfos: {
      url: string;
    }[];
    attachments: {
      filename: string;
      contentType: string;
    }[];
    bodyAsHtml: string;
    bodyAsText: string;
    bodyAsImage: string;
  };
  alertNumber: string;
  alertName: string;
  dedupeCounter?: number;
  alertType: AlertTypes;
  alertStatus: AlertStatuses;
  isAcknowledged?: boolean;
  rsInternalViewedByTenant?: boolean;
  rsInternalReview: {
    tenantID: string;
    alertID: string;
    initialAlertStatus: AlertStatuses;
    finalAlertStatus: AlertStatuses;
    flippedOutcome?: boolean;
    createdOn: Date;
    createdBy: string;
    comment?: string;
    issueCategory?: string;
  };
  alertDetail: {
    category?: string;
    messageID?: string;
    from?: AlertIdentity[];
    to?: AlertIdentity[];
    cc?: AlertIdentity[];
    bcc?: AlertIdentity[];
    reportedBy?: AlertIdentity[];
    senderServer?: string;
    senderServerIP?: string;
    additionalRecipients?: AlertIdentity[];
    identity?: AlertIdentity;
    severity?: AlertSeverity;
    detectionOutcome?: AlertDetectionOutcome;
    host?: string;
    localIP?: string;
    publicIP?: string;
    mitreTechniques?: AlertMitreTechnique[];
    actor?: AlertActor;
    vendorAlertID?: string;
    vendorAlertTitle?: string;
    timestamp?: Date;
    detectionReason?: string[];
    detectionCategory?: string;
    srcIP?: string;
    user?: string;
    br_userID?: string;
    br_userType?: string;
    userDisplayName?: string;
    br_category?;
    br_vendor?: string;
    br_vendorDisplayName?: string;
    br_product?: AlertProduct;
    sensorID?: string;
    br_outcome?: string;
    br_eventName?: string;
    br_type?: string;
    userAgent?: string;
    userAgentData?: {
      browser?: {
        name?: string;
        version?: string;
        displayName?: string;
      };
      device?: {
        model?: string;
        type?: string;
        vendor?: string;
      };
      os?: {
        name?: string;
        version?: string;
        displayName?: string;
      };
    };
    os?: string;
    browser?: string;
    labels?: string;
    vendorAlertDescription?: string;
    vendorSeverity?: AlertSeverity;
    application?: string;
    detectionSource?: string;
    br_labels?: string;
    name?: string;
    outcome?: string;
    srcIPDetail?: {
      brType: string;
      proxy: string;
      type: string;
      organisation: string;
      provider: string;
      country: string;
      region: string;
      asn: string;
      operator?: {
        name: string;
        url: string;
        protocols: string[];
      };
    };
    br_eventID?: string;
    destHost?: string;
    destIP?: string;
    destPort?: string;
    srcHost?: string;
    srcPort?: string;
    url?: string;
    protocol?: string;
    alertID?: string;
    detectionHost?: string;
    urlCategories?: string[];
    domain?: string;
    description?: string;
    region?: string;
    resource?: {
      resourceID?: string;
      outcome?: string;
      resourceType?: string;
      vendorResourceType?: string;
      resourceName?: string;
      resourceStatus?: string;
      resourceRole?: string;
    };
    displayGeo?: string;
    rawAlert?: string;
    artifacts?: Artifact[];
    splitArtifacts?: SplitArtifacts;
    br_productDisplayName?: string;
    action?: AlertAction;
    userSessionID?: string;
  };
  falsificationResult?: FalsificationResults;
  falsificationDate?: Date;
  falsificationBy?: string;
  connectorVendorCode: ConnectorVendorCodes;
  connectorVendorName: string;
  connectorType?: ConnectorTypes;
  createdOn: Date;
  createdBy?: string;
  isEscalated: boolean;
  incidentID: string;
  alertPriority?: AlertPriority;
  isAIEligible?: AlertAIEligible;
  aiVerdict?: VerdictTypes;
}
