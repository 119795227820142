import { SearchFilterParams } from '@customer-web-app/domains/search/services/search-filter-service';
import { AlertStatuses } from '@customer-web-app/domains/alerts/models/alert';

type Params = {
  filters?: SearchFilterParams['alertFilter'];
  enableEndpointAlerts?: boolean;
  enableIdentityAlerts?: boolean;
  enableNetworkAlerts?: boolean;
  enableCloudAlerts?: boolean;
  enableGenericAlerts?: boolean;
  enablePriorityFilter?: boolean;
};

const defaultParamValues: Params = {
  filters: {},
  enableEndpointAlerts: true,
  enableIdentityAlerts: true,
  enableNetworkAlerts: false,
  enableCloudAlerts: false,
  enableGenericAlerts: false,
  enablePriorityFilter: false,
};

export default function getAlertsMainScreen(params?: Params) {
  const { filters } = {
    ...defaultParamValues,
    ...params,
  };

  const alertsFilters = {
    alertStatus: [
      'incident',
      'falsified',
      AlertStatuses.LikelyBenign,
      AlertStatuses.RecommendedBenign,
      AlertStatuses.RecommendedMalicious,
      AlertStatuses.Triaging,
      ...(params?.enableGenericAlerts ? [AlertStatuses.Pending] : []),
    ],
    alertType: [
      'phishing',
      ...(params?.enableEndpointAlerts ? ['endpoint'] : []),
      ...(params?.enableIdentityAlerts ? ['identity'] : []),
      ...(params?.enableNetworkAlerts ? ['network'] : []),
      ...(params?.enableCloudAlerts ? ['cloud'] : []),
      ...(params?.enableGenericAlerts ? ['generic'] : []),
    ],
    ...(params?.enablePriorityFilter
      ? { alertPriority: ['low', 'medium', 'high'] }
      : {}),
    ...filters,
  };

  return `/alerts?filter=${encodeURIComponent(
    JSON.stringify({
      globalFilter: { entities: ['Alert'] },
      alertFilter: {
        ...alertsFilters,
      },
    }),
  )}`;
}
