import useFeatureFlags from '@customer-web-app/domains/shared/hooks/use-feature-flags';
import React from 'react';
import { useRouter } from 'next/router';
import qsStringify from 'qs/lib/stringify';
import qsParse from 'qs/lib/parse';
import { Incident } from '@customer-web-app/domains/incidents/models/incident';
import { Alert } from '@customer-web-app/domains/alerts/models/alert';
import { Search } from '@customer-web-app/domains/search/models/search';
import { UseFormReturn } from 'react-hook-form';
import SearchFilterService, {
  SearchFilterParams,
} from '@customer-web-app/domains/search/services/search-filter-service';
import { gql, useLazyQuery } from '@apollo/client';
import { TimeFrameOptions } from '@customer-web-app/domains/search/models/time-frame-options';
import { timeFrameOptions } from '@customer-web-app/domains/shared/constants/time-frame-options';
import getTimeFrame from '@customer-web-app/domains/shared/services/get-time-frame';
import { SortTypes } from '@customer-web-app/domains/search/enums/sort-types';
import searchSortMapper from '@customer-web-app/domains/search/mappers/search-sort-mapper';
import { FeatureFlagsStructure } from '@customer-web-app/domains/shared/providers/feature-flags-provider';
import useAuthentication from '@customer-web-app/domains/authentication/hooks/use-authentication';

export type SearchResultOffset = {
  from?: number;
  size?: number;
  total: number;
};

export type UseSearchParams = {
  form?: UseFormReturn;
  formValues: SearchFilterParams;
  setParamsOnUrl?: boolean;
  extraRequestLimit?: number;
  debounceKey?: string;
  debounceTimeout?: number;
  currentPage?: number;
};

interface SearchResponse {
  searchV2: {
    items: ((Alert | Incident) & Search)[];
    offset: SearchResultOffset;
  };
}

export const GET_ENTITIES_QUERY = gql`
  query GetEntities(
    $filters: SearchFilter
    $pagination: PaginationSearchOffset
    $sort: [Sort]
  ) {
    searchV2(filters: $filters, pagination: $pagination, sort: $sort) {
      items {
        ... on AlertSearch {
          id
          alertName
          alertNumber
          alertStatus
          alertPriority
          alertType
          createdOn
          dedupeCounter
          incidentID
          isEscalated
          isAIEligible
          aiVerdict
          searchEntityType
          connectorVendorCode
          connectorVendorName
          alertDetail {
            ... on AlertDetailIdentity {
              detectionCategory
              user
              br_userID
              userDisplayName
              srcIP
              severity
            }
            ... on AlertDetailPhishing {
              identity {
                displayName
                email
              }
              reportedBy {
                id
                displayName
                email
              }
              from {
                id
                displayName
                email
              }
            }
            ... on AlertDetailEndpoint {
              detectionOutcome
              severity
              detectionCategory
              host
              actor {
                userDisplayName
              }
            }
            ... on AlertDetailNetwork {
              destIP
              detectionOutcome
              detectionCategory
              srcIP
              severity
            }
            ... on AlertDetailCloud {
              timestamp
              region
              severity
              detectionCategory
              detectionOutcome
              resource {
                resourceType
              }
            }
            ... on AlertDetailGeneric {
              br_product
              detectionOutcome
              srcIP
              userDisplayName
              br_productDisplayName
              br_vendorDisplayName
            }
          }
          sourceDetail {
            ... on AlertSourceDetailPhishing {
              timestamp
            }
          }
        }
        ... on IncidentSearch {
          id
          alertsCount
          applicationsCount
          assignedTo
          closedAsFalsePositive
          closedBy
          closedOn
          createdOn
          dataObjectsCount
          devicesCount
          iamIdentitiesCount
          incidentName
          incidentNumber
          incidentStatus
          incidentType
          isLive
          stepsCount {
            remediation {
              done
              total
            }
          }
          searchEntityType
        }
      }
      offset {
        from
        size
        total
      }
    }
  }
`;

export const SEARCH_PAGE_LIMIT = 20;

const defaultParamValues: UseSearchParams = {
  form: null,
  formValues: null,
  setParamsOnUrl: false,
  extraRequestLimit: 10,
  debounceKey: undefined,
  debounceTimeout: undefined,
  currentPage: 2,
};

function useSearch(params: UseSearchParams) {
  const {
    form,
    formValues,
    setParamsOnUrl,
    debounceKey,
    debounceTimeout,
    currentPage,
  } = {
    ...defaultParamValues,
    ...params,
  };

  const {
    alertsEnableEndpointAlerts,
    incidentsEnablePendingIncidents,
    ...featureFlags
  } = useFeatureFlags();

  const { isSuperUser } = useAuthentication();

  const router = useRouter();
  const [pathname, queryString] = router.asPath.split('?');

  const [searchResults, setSearchResults] = React.useState([]);
  const [searchOffsetResults, setSearchOffsetResults] =
    React.useState<SearchResultOffset>({
      total: 0,
    });
  const [loading, setLoading] = React.useState(true);
  const [filters, setFilters] = React.useState(
    SearchFilterService.buildSearchFilter(
      formValues,
      {
        ...featureFlags,
        alertsEnableEndpointAlerts,
      } as FeatureFlagsStructure,
      isSuperUser,
    ),
  );
  const [timeFrame, setTimeFrame] = React.useState<TimeFrameOptions>(
    (router.query?.timeFrame as TimeFrameOptions) || 'Last 30 Days',
  );

  const [alertsSort, setAlertsSort] = React.useState<SortTypes>(
    (router.query?.sort as SortTypes) || SortTypes.TimestampDesc,
  );

  const [fetch, { fetchMore }] = useLazyQuery<SearchResponse>(
    GET_ENTITIES_QUERY,
    {
      fetchPolicy:
        process.env.NEXT_PUBLIC_ENVIRONMENT === 'test'
          ? 'no-cache'
          : 'cache-and-network',
      notifyOnNetworkStatusChange: true,
      context: {
        debounceKey,
        debounceTimeout,
      },
    },
  );

  async function requestQuery(filters, sort = []) {
    setLoading(true);

    let results = [];

    const { data } = await fetch({
      variables: {
        filters,
        pagination: {
          from: 0,
          size: SEARCH_PAGE_LIMIT,
        },
        sort,
      },
    });

    results = data?.searchV2?.items || [];

    setSearchResults(results);
    setSearchOffsetResults(data?.searchV2?.offset);
    setLoading(false);
  }

  async function getSearchNextPage(pageNumber: number) {
    setLoading(true);

    let results = [];

    const { data } = await fetchMore({
      variables: {
        filters,
        pagination: {
          from: pageNumber * SEARCH_PAGE_LIMIT,
          size: SEARCH_PAGE_LIMIT,
        },
      },
    });

    results = data?.searchV2?.items || [];

    setSearchResults(results);
    setSearchOffsetResults(data?.searchV2?.offset);
    setLoading(false);

    return Promise.resolve();
  }

  // Calls API again when change the filter form
  React.useEffect(() => {
    const sort = searchSortMapper[alertsSort];

    const newFilters = SearchFilterService.buildSearchFilter(
      formValues,
      {
        ...featureFlags,
        alertsEnableEndpointAlerts,
      } as FeatureFlagsStructure,
      isSuperUser,
    );

    if (newFilters != null) {
      setSearchResults([]);
      setFilters(newFilters);

      requestQuery(newFilters, sort);

      if (setParamsOnUrl) {
        router.replace(
          `${pathname}${qsStringify(
            {
              ...qsParse(queryString),
              filter: JSON.stringify(newFilters),
              timeFrame,
              sort: alertsSort,
            },
            {
              addQueryPrefix: true,
            },
          )}`,
        );
      }
    }
  }, [formValues, alertsSort]);

  React.useEffect(() => {
    if (setParamsOnUrl) {
      router.replace(
        `${pathname}${qsStringify(
          {
            ...qsParse(queryString),
            page: currentPage,
          },
          {
            addQueryPrefix: true,
          },
        )}`,
      );
    }
  }, [currentPage]);

  function setTimeFrameOption(option: TimeFrameOptions) {
    setTimeFrame(option);
  }

  function resetTimeFrame() {
    setTimeFrameOption('Last 30 Days');

    form.setValue('globalFilter.dateRange.from', getTimeFrame('Last 30 Days'));
    form.setValue('globalFilter.dateRange.to', new Date());
  }

  function updateDatesAccordingTimeFrame(timeFrame: TimeFrameOptions) {
    if (timeFrameOptions.includes(timeFrame)) {
      form.setValue('globalFilter.dateRange.from', getTimeFrame(timeFrame));
      form.setValue('globalFilter.dateRange.to', new Date());
    }
  }

  function resetFilter(
    form: UseFormReturn,
    defaultSearchFilter: SearchFilterParams,
  ) {
    setTimeFrameOption('Last 30 Days');

    form.reset({
      ...defaultSearchFilter,
      globalFilter: { ...form.getValues('globalFilter') },
    });
  }

  function removeFilterKey(form: UseFormReturn, key: string) {
    const objectPath = key.split('.');
    const value = objectPath.pop();
    const objectKeys = objectPath.map((item) => `['${item}']`).join('');
    const currentValue = eval(`formValues${objectKeys}`);

    form.setValue(
      objectPath.join('.'),
      currentValue.filter((item) => item !== value),
    );
  }

  const searchResultsFiltered = searchResults?.filter((result) => {
    if (result.searchEntityType === 'Alert') {
      return true;
    }

    if (result.searchEntityType === 'Incident') {
      return (
        incidentsEnablePendingIncidents || result?.incidentStatus !== 'pending'
      );
    }

    return true;
  });

  const adjustedSearchOffsetResults = {
    ...searchOffsetResults,
    total:
      searchOffsetResults?.total -
      (searchResults.length - searchResultsFiltered.length),
  };

  return {
    searchResults: searchResultsFiltered,
    searchOffsetResults: adjustedSearchOffsetResults,
    searchResultsCount: searchResultsFiltered.length,
    isLoadingSearch: loading,
    timeFrame,
    getSearchNextPage,
    resetFilter,
    removeFilterKey,
    setTimeFrameOption,
    resetTimeFrame,
    updateDatesAccordingTimeFrame,
    alertsSort,
    setAlertsSort,
  };
}

export default useSearch;
