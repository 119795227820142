import {
  Incident,
  IncidentStatuses,
  IncidentSteps,
} from '@customer-web-app/domains/incidents/models/incident';

export default function getIncidentStep(incident: Incident): IncidentSteps {
  if (!incident) {
    return;
  }

  if (
    incident?.incidentStatus === IncidentStatuses.Closed ||
    incident?.incidentStatus === IncidentStatuses.Pending ||
    incident?.incidentStatus === IncidentStatuses.OnHold
  ) {
    return incident.incidentStatus;
  }

  if (
    incident?.stepsCount?.remediation?.done ===
      incident?.stepsCount?.remediation?.total &&
    !incident?.isLive
  ) {
    return 'remediated';
  }

  return IncidentStatuses.Opened;
}
