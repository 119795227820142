import Icon from '../icon';
import classNames from 'classnames';
import InputText, { InputTextProps } from '../input-text';
import React, { useState } from 'react';
import { RefCallBack } from 'react-hook-form';

function padWithLeadingZeros(num: number) {
  return String(num).padStart(2, '0');
}

function isValid(val: string) {
  if (!val) return true;

  const timeRegex = /^([0-1]?[0-9]|2[0-3])?:?([0-5]?[0-9])?:?([0-5]?[0-9])?$/;
  return timeRegex.test(val);
}

type Props = {
  initTime?: string;
  label?: string;
  placeholder?: string;
  disabled?: boolean;
  onTimeChange?: (time: string) => void;
  withButtons?: boolean;
  focusedInputId?: string;
} & InputTextProps;

const InputTimeWithSeconds = React.forwardRef<
  RefCallBack,
  Props &
    (
      | React.InputHTMLAttributes<HTMLInputElement>
      | React.TextareaHTMLAttributes<HTMLTextAreaElement>
    )
>(
  (
    {
      initTime = '00:00:00',
      onTimeChange,
      withButtons = true,
      focusedInputId,
      placeholder,
      ...props
    },
    ref,
  ) => {
    const [time, setTime] = useState(initTime || '');
    const id = React.useMemo(() => Math.random().toString(36).slice(2), []);
    const inputRef = React.useRef<HTMLInputElement | null>(null);

    let lastVal = '';

    React.useEffect(() => {
      setTime(initTime);
    }, [initTime]);

    React.useEffect(() => {
      if (focusedInputId === id && inputRef.current) {
        inputRef.current.focus();
      }
    }, [focusedInputId, id]);

    const onChangeHandler = (val: string) => {
      if (val === time) {
        return;
      }
      if (isValid(val)) {
        if (
          (val.length === 2 || val.length === 5) &&
          lastVal.length !== val.length + 1 &&
          val.split(':').length < 3
        ) {
          val = val + ':';
        }

        if (
          (val.length === 2 || val.length === 5) &&
          lastVal.length === val.length + 1
        ) {
          val = val.slice(0, val.length - 1);
        }

        if (val.length > 8) {
          return false;
        }

        lastVal = val;

        setTime(val);

        if (val.length === 8) {
          onTimeChange?.(val);
        }
      }
      return null;
    };

    const incrementTime = () => {
      const currentValue = inputRef.current?.value;
      if (!currentValue) return;

      const timeParts = currentValue.split(':');
      if (timeParts.length < 3) return;

      const [hours, minutes, seconds] = timeParts.map(Number);

      let totalSeconds = hours * 3600 + minutes * 60 + seconds;

      totalSeconds = (totalSeconds + 1) % (24 * 3600);

      const newHours = Math.floor(totalSeconds / 3600);
      const newMinutes = Math.floor((totalSeconds % 3600) / 60);
      const newSeconds = totalSeconds % 60;

      onChangeHandler(
        `${padWithLeadingZeros(newHours)}:${padWithLeadingZeros(newMinutes)}:${padWithLeadingZeros(newSeconds)}`,
      );
    };

    const decrementTime = () => {
      const currentValue = inputRef.current?.value;
      if (!currentValue) return;

      const timeParts = currentValue.split(':');
      if (timeParts.length < 3) return;

      const [hours, minutes, seconds] = timeParts.map(Number);

      let totalSeconds = hours * 3600 + minutes * 60 + seconds;

      totalSeconds = totalSeconds > 0 ? totalSeconds - 1 : 24 * 3600 - 1;

      const newHours = Math.floor(totalSeconds / 3600);
      const newMinutes = Math.floor((totalSeconds % 3600) / 60);
      const newSeconds = totalSeconds % 60;

      onChangeHandler(
        `${padWithLeadingZeros(newHours)}:${padWithLeadingZeros(newMinutes)}:${padWithLeadingZeros(newSeconds)}`,
      );
    };

    return (
      <div className="relative select-none">
        <InputText
          {...props}
          data-id={id}
          value={time}
          onChange={(
            e: React.ChangeEvent<HTMLInputElement & HTMLTextAreaElement>,
          ) => {
            onChangeHandler(e?.target?.value);
          }}
          ref={(node) => {
            if (node instanceof HTMLInputElement) {
              inputRef.current = node;
            }

            if (typeof ref === 'function') {
              ref(node);
            } else if (ref) {
              ref.current = node;
            }
          }}
        />

        {withButtons && (
          <>
            <button
              type="button"
              className={classNames(
                'rotate-180 absolute cursor-pointer right-3',
                {
                  'top-[1.6rem]': props.label,
                  'top-[0.125rem]': !props.label,
                },
              )}
              onClick={incrementTime}
              aria-label="Increase field value"
            >
              <Icon
                name="carrot-down"
                size={16}
                className="hover:fill-black dark:hover:fill-white transition-colors ease-in-out duration-200"
              />
            </button>

            <button
              type="button"
              className={classNames(
                'absolute cursor-pointer right-3 bottom-2',
                {
                  'top-[2.75rem]': props.label,
                  'top-[1.45rem]': !props.label,
                },
              )}
              onClick={decrementTime}
              aria-label="Decrease field value"
            >
              <Icon
                name="carrot-down"
                size={16}
                className="hover:fill-black dark:hover:fill-white transition-colors ease-in-out duration-200"
              />
            </button>
          </>
        )}
      </div>
    );
  },
);

export default InputTimeWithSeconds;
